<template>
  <div style="border-bottom: 1px solid">
    <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
      <el-radio-button :label="false">展开</el-radio-button>
      <el-radio-button :label="true">收起</el-radio-button>
    </el-radio-group> -->
    <el-menu :collapse="isCollapse" router unique-opened>
      <template v-for="(item, index) in navList">
        <el-submenu v-if="item.tab" :index="item.path">
          <template slot="title">
            <div class="menuItem">
              <img :src="item.url" alt="">
              <span slot="title">{{ item.navName }}</span>
            </div>
          </template>
          <el-menu-item-group>
            <el-menu-item :index="`${item.path}?name=${child.name}`" v-for="child in item.tab">
              <span style="padding-left: 14px;">{{ child.label }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-menu-item v-else :index="item.path" @click="navItem(item, index)">
          <div class="menuItem">
            <img :src="item.url" alt="">
            <span slot="title">{{ item.navName }}</span>
          </div>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { local } from "@/utils/storage";
export default {
  name: 'LeftMenu',
  data() {
    return {
      isCollapse: false,
      navList: [
        {
          path: "/HomePage",
          navName: "项目管理",
          url: require('../assets/image/icon/project-management1.png'),
        },
        {
          path: "/questionnaire",
          navName: "问卷中心",
          url: require('../assets/image/icon/questionnaire-enter1.png'),
          tab: [
            { label: '我的问卷', name: 'first' },
            { label: '模板中心', name: 'second' }
          ]
        },
        {
          path: "/patentList",
          navName: "患者中心",
          url: require('../assets/image/icon/a222.png'),
        },
        {
          path: "/DataCenter",
          navName: "已收集问卷",
          url: require('../assets/image/icon/patient-list1.png'),
          tab: [
            { label: '问卷查询', name: 'first' },
            { label: '患者查询', name: 'second' },
            { label: '草稿箱', name: 'third' }
          ]
        },
        {
          path: "/derive",
          navName: "查询导出",
          url: require('../assets/image/icon/query-export.png'),
          tab: [
            { label: '问卷查询导出', name: 'first' },
            { label: '患者查询导出', name: 'second' }
          ]
        },
        {
          path: "/DataAnalysis",
          navName: "数据分析",
          url: require('../assets/image/icon/a111.png'),
        },
        {
          path: "/statistics",
          navName: "数据统计",
          url: require('../assets/image/icon/data-statistics1.png'),
        },
        {
          path: "/task",
          navName: "随访任务",
          url: require('../assets/image/icon/follow-up-task.png'),
          tab: [
            { label: '任务监测', name: 'first' },
            { label: '规则监测', name: 'second' }
          ]
        },
        {
          path: "/report",
          navName: "工作汇报",
          url: require('../assets/image/icon/work-report.png'),
        },
        {
          path: "/RobotCenter",
          navName: "机器人中心",
          url: require('../assets/image/icon/a333.png'),
          tab: [
            { label: '机器人管理', name: '0' },
            { label: '公共知识库', name: '1' }
          ]
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    navItem(item, index) {
      // console.log(item.path)
      local.set('IsRtc', true)
      this.$store.commit("menustate", index + 1);
      this.$router.push(item.path)
    },
  }
}
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 24px;
    width: 24px;
  }
}

.el-menu {
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  min-width: 201px;
}
</style>
