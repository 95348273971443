<template>
  <div class="MenuCommon">
    <div class="layout" :class="$store.state.menuFlag && 'layoutGrid'">
      <Handler v-if="$store.state.menuFlag" />
      <div class="MenuCommon_body" :class="$store.state.menuFlag && 'MenuCommon_body_grid'">
        <LeftMenu v-if="$store.state.menuFlag"></LeftMenu>
        <headassembly v-else></headassembly>
        <router-view></router-view>
      </div>
    </div>
    <div class="loading" v-if="show">
      <div class="loading_head">
        <p>
          <span>我的任务</span>
        </p>
        <p class="el-icon-close" @click="close"></p>
      </div>
      <div class="loading_content">
        <p style="margin-bottom: 10px">尊敬的医生,您好!</p>
        <p style="margin-bottom: 20px; color: #666">
          请及时完成您的待办任务!（点击下方数字链接查看详情）。
        </p>
        <div v-for="(item, index) in list" :key="index" @click="add(item)" style="margin-bottom: 20px">
          <img src="../assets/image/notice/24944.png" v-show="index == 0" style="vertical-align: middle" />
          <img src="../assets/image/notice/24945.png" v-show="index == 1" style="vertical-align: middle" />
          <img src="../assets/image/notice/24946.png" v-show="index == 2" style="vertical-align: middle" />
          <!-- <span class="el-icon-phone" v-show="index==0" style="margin-right: 10px;"></span>
                <span class="el-icon-s-promotion" v-show="index==1"  style="margin-right: 10px;"></span>
                <span class="el-icon-user-solid"  v-show="index==2"  style="margin-right: 10px;"></span> -->
          <span style="
              width: 50px;
              text-align: right;
              display: inline-block;
              margin-right: 30px;
            ">{{ item.ChannelName }}</span><span>(<span style="color: #3388ff">{{ item.Count }}</span>)</span>
        </div>
      </div>
    </div>
    <div v-else class="img" :class="init ? 'center' : 'right'" v-show="isShow" @click="big">
      <!-- <transition enter-active-class="animate__animated animate__backInRight" leave-active-class="animate__animated animate__backOutRight"> -->
      <img :class="init ? 'big' : 'small'" v-show="isShow" src="../assets/image/icon/RightcenterRobot.png" />
      <!-- </transition> -->
    </div>
    <div>
      <el-dialog :visible.sync="dialogVisible" fullscreen width="100%">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">任务列表</span>
        </div>
        <div class="loading_table_head" style="margin-bottom: 20px">
          <span style="display: inline-block; width: 300px; margin-right: 10px"><el-input v-model="input"
              placeholder="请输入内容"></el-input></span>
          <el-button type="primary" icon="el-icon-search">搜索</el-button>
        </div>
        <div style="margin-bottom: 10px">
          <el-table border :data="tableData" ref="caozuotable"
            :header-cell-style="{ backgroundColor: 'rgb(240, 247, 253)' }"
            style="width: 100%; height: 600px; margin-bottom: 20px">
            <el-table-column align="center" prop="ProjectName" label="项目名称" width="280">
            </el-table-column>
            <el-table-column align="center" prop="QuestionnaireName" label="问卷名称" width="280">
            </el-table-column>
            <el-table-column align="center" prop="TargetName" label="患者姓名" width="100">
            </el-table-column>
            <el-table-column align="center" prop="TargetTel" label="患者手机" width="140">
            </el-table-column>
            <el-table-column align="center" prop="TriggerTime" label="随访日期" width="200">
            </el-table-column>
            <el-table-column align="center" prop="UserName" label="主管医生" width="200">
            </el-table-column>
            <el-table-column align="center" prop="UserTel" label="主管医生电话" width="200">
            </el-table-column>
            <el-table-column align="center" prop="ChannelName" label="随访方式">
            </el-table-column>
            <el-table-column align="center" prop="StatusName" label="状态">
            </el-table-column>
            <el-table-column prop="Frq" label="访视次数" align="center">
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-link @click="handleClick(scope.row)" type="text" :underline="false" style="margin-right: 11px"><i
                    class="el-icon-search"></i>填写问卷</el-link>
                <!-- <el-button  size="small">填写问卷</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <div>
            <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
              @pagination="MyMissionDetailed">
            </Pagination>
          </div>
        </div>
        <div class="btn">
          <el-button @click="queding">取 消</el-button>
          <el-button type="primary" @click="queding">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="QuestionnaireFilling">
      <el-dialog :visible.sync="QuestionnaireFillingDialog" fullscreen :before-close="handleClose">
        <iframe :src="Url" frameborder="0" width="100%" height="1080px" ref="myFrame"></iframe>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { local } from "@/utils/storage";
import login from "../api/login";
import Pagination from "../components/Pagination/index.vue";
import headassembly from "../components/headassembly.vue";
import LeftMenu from "../components/LeftMenu.vue";
import Handler from "../components/Hander.vue"
export default {
  data() {
    return {
      QuestionnaireFillingDialog: false,
      show: false,
      list: [],
      dialogVisible: false,
      tableData: [],
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      Channel: null,
      input: "",
      Url: "",
      isShow: true,
      init: true,
    };
  },
  components: {
    headassembly,
    Pagination,
    LeftMenu,
    Handler
  },
  created() {
    this.MyMission();
  },
  mounted() {
    window.onresize = () => {
      // this.refs.caozuotable.doLayout();
      // myTable是表格的ref属性值
      if (this.$refs.caozuotable && this.$refs.caozuotable.doLayout) {
        this.$refs.caozuotable.doLayout();
      }
    };
  },
  methods: {
    big() {
      console.log(this.init);
      this.init = !this.init;
    },
    handleClose() {
      this.MyMission();
      this.MyMissionDetailed();
    },
    handleClick(item) {
      this.Url = "";
      //   const parameter={
      //       ProjectId:item.ProjectId
      //   }
      //   questionnaire.CallBackQuestionnaireData(parameter).then(res=>{
      //                console.log(res);
      //               if(res.data.Status==1){
      //                     let sysData=JSON.parse(res.data.Data)
      //                     sysData['FollowUpTaskId']=item.Id
      //                     sysData['FillType']='D'

      //                     this.QuestionnaireFillingDialog=true
      //                     this.Url=`${window.global_config.BASE_URL1}/#/P?sysData=${JSON.stringify(sysData)}&type=1&qid=${item.ProjectQuestionnaireId}&userId=${item.TargetId}&userName=${item.UserName}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&phone=${item.TargetTel}&visitsNumber=${item.Frq}`
      //               }else{
      //                 alert(res.data.Message+"++++++")
      //               }
      //   })
      if (this.tableData && this.tableData[0].CallBackInfo.IsSuccess) {
        this.QuestionnaireFillingDialog = true;
        this.Url = `${window.global_config.BASE_URL1}/#/P?sysData=${this.tableData[0].CallBackInfo.CallBackParameterEncrypt}&type=1&qid=${item.ProjectQuestionnaireId}&userId=${item.TargetId}&userName=${item.UserName}&fillinId=${local.get("UserId")}&fillinName=${local.get("UserName")}&phone=${item.TargetTel}&visitsNumber=${item.Frq}`;
      }
      // console.log(this.Url);
    },
    //关闭
    close() {
      local.set("LoadingFalse", false);
      this.show = local.get("LoadingFalse");
      //   this.MyMission()
    },
    queding() {
      this.dialogVisible = false;
      this.MyMission();
    },
    //我的任务
    MyMission() {
      login.MyMissionData().then((res) => {
        console.log(res, "我的任务");
        if (res.data.Status == 1) {
          this.list = res.data.Data;
          if (
            this.list[0].Count == 0 &&
            this.list[1].Count == 0 &&
            this.list[2].Count == 0
          ) {
            local.set("LoadingFalse", false);
            this.show = local.get("LoadingFalse");
          } else {
            local.set("LoadingFalse", true);
            this.show = local.get("LoadingFalse");
          }
        } else {
          alert(res.data.Message);
        }
      });
    },
    add(item) {
      this.dialogVisible = true;
      this.Channel = item.Channel;
      this.MyMissionDetailed();
    },
    MyMissionDetailed() {
      const parameter = {
        Key: this.listQuery.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        Channel: this.Channel,
      };
      login.MyMissionDetailedData(parameter).then((res) => {
        console.log(res, "我的任务明细");
        if (res.data.Status == 1) {
          this.total = res.data.Data.total;
          this.tableData = res.data.Data.rows;
          this.$refs.caozuotable.doLayout();
        } else {
          alert(res.data.Message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes example {
  0% {
    border-radius: 50%;
    right: 145px;
    bottom: 130px;
    width: 100px;
    height: 100px;
  }

  25% {
    border-radius: 50%;
    right: 105px;
    bottom: 90px;
    width: 80px;
    height: 80px;
  }

  50% {
    border-radius: 50%;
    right: 85px;
    bottom: 60px;
    width: 60px;
    height: 60px;
  }

  75% {
    border-radius: 50%;
    right: 45px;
    bottom: 40px;
    width: 40px;
    height: 40px;
  }

  100% {
    border-radius: 50%;
    right: 0px;
    bottom: 0px;
    width: 20xp;
    height: 20px;
  }
}

.MenuCommon {
  width: 100%;
  height: 100%;

  .layout {
    height: 100%;
  }

  .layoutGrid {
    display: grid;
    grid-template-rows: 70px 1fr;
  }

  .MenuCommon_head {
    width: 100%;
    height: 80px;
  }

  .MenuCommon_body {
    width: 100%;
    height: 100%;
    background: #f0f7fd;
    overflow: auto;
  }

  .MenuCommon_body_grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: 100%;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .MenuCommon_body {
      overflow: auto;
      // height: calc(100% - 80px) !important;
    }

    .img {
      display: none !important;
    }

    .loading {
      display: none !important;
    }
  }


  .img {
    width: 100px;
    height: 100px;
    // background: #fff;
    position: fixed;
    right: 80px;
    bottom: 100px;
    z-index: 999;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
    // animation-name: example;
    // animation-duration: 4s;
    // animation-timing-function: ease-in;

    &.right {
      right: -20px;
      bottom: 50px;
    }

    &.cetner {
      right: 145px;
    }

    img {
      width: 100%;
      height: 100%;
      transition: all 1s;
    }

    .big {
      width: 100px;
      height: 100px;
    }

    .small {
      width: 30px;
      height: 30px;
    }
  }

  .loading {
    width: 340px;
    height: 300px;
    background: #fff;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 999;
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;

    .loading_head {
      display: flex;
      justify-content: space-between;
      height: 40px;
      // background: linear-gradient(360deg, #8bd4fc 0%, #69a7fb 100%);
      background-color: rgb(24, 144, 255);
      font-size: 18px;
      color: #fff;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 4px 4px 0px 0px;

      .el-icon-close {
        font-size: 22px;
      }
    }

    .loading_content {
      padding: 20px;
      box-sizing: border-box;
    }
  }
}
</style>
